// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/key.ts"
);
import.meta.hot.lastModified = "1731716284373.246";
}
// REMIX HMR END

import { type Key } from 'ts-key-enum'

// We cannot simply check equality with the ts-key-enum due to the TS ESLint
// no-unsafe-enum-comparison rule. I do not want to disable the rule. There is
// no way to override the type of an existing field on an interface. TS supports
// augmenting an existing interface using module augmentation but does not
// support changing the type of an existing field.
//
// I do not want to remove the ts-key-enum usage as I appreciate the type safety
// that ensures there aren't typos in our key checks.
//
// Ref: https://www.typescriptlang.org/docs/handbook/declaration-merging.html#merging-interfaces
// Ref: https://typescript-eslint.io/rules/no-unsafe-enum-comparison/
// Ref: https://www.npmjs.com/package/ts-key-enum
export function isKey<K extends Key>(eventKey: string, key: K): eventKey is K {
  return eventKey === key
}
